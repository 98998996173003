export default {
  axiosAddDepositBegin(state) {
    state.loading = true;
    state.pixData = null;
    state.error = null;
  },

  axiosAddDepositSuccess(state, pixData) {
    state.loading = false;
    state.pixData = pixData;
    state.error = null;
  },

  axiosAddDepositErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosReadDepositBegin(state) {
    state.loading = true;
    state.error = null;
  },

  axiosReadDepositSuccess(state, data) {
    state.loading = false;
    state.data = data;
    state.error = null;
  },

  axiosReadDepositErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  axiosCancelDepositBegin(state) {
    state.loading = true;
    state.error = null;
  },

  axiosCancelDepositSuccess(state) {
    state.loading = false;
    state.error = null;
  },

  axiosCancelDepositErr(state, err) {
    state.loading = false;
    state.error = err;
  }
};
import mutations from './mutations';
import { DataServiceHedgeFund } from '@/config/dataService/dataServiceHedgeFund';

const state = () => ({
  data: [],
  loading: true,
  error: null
});

const actions = {  
  async axiosHedgeFundGetIndicatorData({ commit }, request) {
    try {
      await commit('indicatorReadBegin');
      const query = await DataServiceHedgeFund.get('/indicator?' + request);
      await commit('indicatorReadSuccess', query.data);
    } catch (err) {
      await commit('indicatorReadErr', err);
    }
  },
  async axiosHedgeFundGetDollarPriceData({ commit }, request) {
    try {
      await commit('dollarPriceReadBegin');
      const query = await DataServiceHedgeFund.get('/dollarPrice?' + request);
      await commit('dollarPriceReadSuccess', query.data);
    } catch (err) {
      await commit('dollarPriceReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
export default {
  loginBegin(state) {
    state.loading = true;
  },
  loginSuccess(state, data) {
    state.loading = false;
    state.login = data;
  },

  loginErr(state, err) {
    state.loading = false;
    state.error = err;
    state.login = false;
  },

  logoutBegin(state) {
    state.loading = true;
  },

  logoutSuccess(state, data) {
    state.loading = false;
    state.login = data;
  },

  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  registerBegin(state) {
    state.loading = true;
  },

  registerSuccess(state, query) {
    state.loading = false;
    state.data = query.data;
    state.error = query.error;
  },

  registerErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  forgotPasswordBegin(state) {
    state.loading = true;
  },

  forgotPasswordSuccess(state, query) {
    state.loading = false;
    state.data = null;
    state.error = query.error;
  },

  forgotPasswordErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  resetPasswordBegin(state) {
    state.loading = true;
  },

  resetPasswordSuccess(state, query) {
    state.loading = false;
    state.data = null;
    state.error = query.error;
  },

  resetPasswordErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

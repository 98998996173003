import mutations from './mutations';
import Cookies from 'js-cookie';
import { DataServiceHedgeFund } from '@/config/dataService/dataServiceHedgeFund';
import { setItem } from '@/utility/localStorageControl';

function getCookie(){
  let logedIn = Cookies.get('logedIn');
  if (logedIn) logedIn = JSON.parse(logedIn);
  
  return logedIn;
}


const state = () => ({
  login: getCookie(),
  loading: false,
  error: null,
  data: null
});

const actions = {
  async login({ commit }, request) {
    try {
      commit('loginBegin');
      const query = await DataServiceHedgeFund.post('login', request);
      setItem('access_token', query.data.accessToken);
      Cookies.set('logedIn', JSON.stringify(query.data));
      commit('loginSuccess', query.data);
    } catch (err) {
      commit('loginErr', err);
    }
  },
  async logOut({ commit }) {
    try {
      commit('logoutBegin');
      Cookies.remove('logedIn');
      commit('logoutSuccess', null);
    } catch (err) {
      commit('logoutErr', err);
    }
  },
  async register({ commit }, request) {
    try {
      commit('registerBegin');
      const query = await DataServiceHedgeFund.post('register', request);
      commit('registerSuccess', query);     
    } catch (err) {
      commit('registerErr', err);
    }
  },

  async forgotPassword({ commit }, request) {
    try {
      commit('forgotPasswordBegin');
      const query = await DataServiceHedgeFund.post('forgotPassword', request);
      commit('forgotPasswordSuccess', query);     
    } catch (err) {
      commit('forgotPasswordErr', err);
    }
  },

  async resetPassword({ commit }, request) {
    try {
      commit('resetPasswordBegin');
      const query = await DataServiceHedgeFund.post('resetPassword', request);
      commit('resetPasswordSuccess', query);     
    } catch (err) {
      commit('resetPasswordErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};

import mutations from './mutations';
import { DataServiceHedgeFund } from '@/config/dataService/dataServiceHedgeFund';

const state = () => ({
  data: [],
  loading: false,
  error: null
});

const actions = {  
  async axiosHedgeFundGetOverviewData({ commit }, productId) {
    try {
      await commit('overviewReadBegin');
      const query = await DataServiceHedgeFund.get('/overview/' + productId);
      await commit('overviewReadSuccess', query.data);
    } catch (err) {
      await commit('overviewReadErr', err);
    }
  },
  async axioMyHedgeFundGetOverviewData({ commit }, productId) {
    try {
      await commit('overviewReadBegin');
      const query = await DataServiceHedgeFund.get('/my-overview/' + productId);
      await commit('overviewReadSuccess', query.data);
    } catch (err) {
      await commit('overviewReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
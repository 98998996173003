import mutations from './mutations';
import { DataServiceHedgeFund } from '@/config/dataService/dataServiceHedgeFund';
import { notification } from 'ant-design-vue';

const state = () => ({
  data: [],
  loading: false,
  error: null,
});

const actions = {
  async axiosGetDepositsData({ commit }) {
    try {
      await commit('axiosReadDepositBegin');
      const query = await DataServiceHedgeFund.get('/deposits');
      await commit('axiosReadDepositSuccess', query.data);
    } catch (err) {
      await commit('axiosReadDepositErr', err);
    }
  },

  async axiosAddDeposit({ commit }, request) {
    try {
      await commit('axiosAddDepositBegin');
      let response = await DataServiceHedgeFund.post('products/' + request.productId + '/deposits/', { totalAmount: request.value, cpf: request.cpf });
      await commit('axiosAddDepositSuccess', response.data);
    } catch (err) {
      await commit('axiosAddDepositErr', err);
      notification['error']({
        message: 'Novo depósito',
        description: err.message,
        duration: 5,
        placement: "bottomRight"})
    }
  },

  async axiosCancelDeposit({ commit }, request) {
    try {
      await commit('axiosCancelDepositBegin');
      await DataServiceHedgeFund.delete('/deposits/' + request);
      await commit('axiosCancelDepositSuccess');
    } catch (err) {
      await commit('axiosCancelDepositErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};